<template>
  <div class>
    <div class="block grey_line stiky-searchbar">
      <nav
        class="navbar-transparent"
        style="background-color: none !important"
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand">
          <a
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" class="navbar-menu">
          <div class="navbar-start">
            <div class="navbar-item">
              <div class="level-item center" style="margin-left: 0.4rem">
                <figure class="image is-48x48">
                  <img
                    class="logo-with-name"
                    src="../assets/nobgblackbig2.png"
                    alt="https://bulma.io/images/placeholders/96x96.png"
                  />
                </figure>
              </div>
            </div>
            <div class="navbar-item">
              <b-input
                v-model="searchValue"
                rounded
                placeholder="e.g. I9 Form for User"
                icon-pack="fas"
                icon="search"
                icon-right="close-circle"
                custom-class="input-search"
                icon-right-clickable
                @icon-right-click="searchValue = ''"
              >
              </b-input>
            </div>
          </div>
          <div class="navbar-end">
            <div v-if="$route.path == '/home'" class="navbar-item">
              <button
                class="button is-lightblue is-outlined is-small is-rounded"
                @click="callVerifyPopup"
              >
                <span>Verify</span>
                <span class="icon is-small buttom-icon-container">
                  <img src="../assets/icon_svg/Group.png" />
                </span>
              </button>
            </div>
            <div v-if="$route.path == '/home'" class="navbar-item">
              <button
                class="button is-lightblue is-small is-rounded"
                @click="callCreatePopup"
                :disabled="!$store.getters.getUserDetails.org_account"
              >
                <span>Create</span>
                <span
                  class="icon is-small buttom-icon-container-white creat-icon-container"
                >
                  <img src="../assets/icon_svg/cross.png" />
                </span>
              </button>
            </div>

            <div class="navbar-item">
              <!-- <a class="navbar-item" slot="trigger" role="button">
                <span
                  v-if="notifyArr.length > 0"
                  title="Badge top right"
                  class="ribbon"
                  >{{ notifyArr.length }}</span
                >
                
                <b-dropdown
                  v-if="!justHeader"
                  position="is-bottom-left"
                  append-to-body
                  :mobile-modal="false"
                  aria-role="menu"
                  expanded
                >
                  

                  <b-dropdown-item custom aria-role="menuitem">
                    <p style="display: flex">
                      <span>Notifications</span>
                      <span
                        @click="clearNotify"
                        class="on-end"
                        style="width: 73px; align-items: center"
                      >
                        <a>
                          <i class="fas fa-times"></i>
                        </a>
                      </span>
                    </p>
                    <hr class="dropdown-divider" />
                    <div
                      v-if="notifyArr.length > 0"
                      class="notification-list-scroll"
                    >
                      <b-notification
                        v-for="(item, index) in notifyArr"
                        :key="index"
                        type="is-light"
                        aria-close-label="Close notification"
                      >
                        <b-icon
                          size="is-small"
                          v-if="item.document_modified"
                          :icon="'file-edit-outline'"
                        ></b-icon>
                        {{ item.msg }}
                      </b-notification>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
               
                  <b-dropdown
                    position="is-bottom-left"
                    v-if="!justHeader"
                    aria-role="menu"
                    
                    :triggers="['hover']"
                  >
                    <template #trigger>
                      <figure class="image is-32x32">
                        <avatar
                          :size="32"
                          :username="msUserName"
                          :src="userPhoto"
                        ></avatar>
                      </figure>
                    </template>
                    <b-dropdown-item
                      @click="logout"
                      value="logout"
                      aria-role="menuitem"
                    >
                      <div class="level">
                        <div class="level-left">
                          <div class="level-item">
                            <i class="fas fa-sign-out-alt"></i>
                          </div>
                          <div class="level-item">Sign Out</div>
                        </div>
                        <div class="level-right"></div>
                      </div>
                    </b-dropdown-item>
                  </b-dropdown>

                  <label class="center-imp" style="margin: 0px 20px 0px 15px">
              <span>{{ accType }}</span>
            </label> 
                
               
              </a> -->
              <a class="navbar-item">
               
                <b-dropdown position="is-bottom-left" :triggers="['hover']"  aria-role="list">
                  <template #trigger>
                      <figure class="image is-32x32">
                        <avatar
                          :size="32"
                          :username="msUserName"
                          :src="userPhoto"
                        ></avatar>
                      </figure>
                    </template>

                    <b-dropdown-item custom aria-role="menuitem">
                        Logged as <b>{{ msUserName }}</b>
                    </b-dropdown-item>
                    <hr class="dropdown-divider">
            <b-dropdown-item @click="logout" aria-role="listitem">
              <div class="level">
                        <div class="level-left">
                          <div class="level-item">
                            <i class="fas fa-sign-out-alt"></i>
                          </div>
                          <div class="level-item">Sign Out</div>
                        </div>
                        <div class="level-right"></div>
                      </div>
            </b-dropdown-item>
        </b-dropdown>
        <b-modal :active.sync="isComponentModalActive">
                  <div class="box" style="width: 50%; margin-left: 25%">
                    <div class="field">
                      <label class="label"
                        >Email(s)<br /><span class="subtitle is-7"
                          >Separate emails with comma(,)</span
                        ></label
                      >
                      <div class="control">
                        <input
                          class="input disabled"
                          id="profile-assignee"
                          v-model="invited"
                        />
                      </div>
                    </div>
                    <div class="field is-grouped">
                      <div class="control">
                        <button
                          id="submit-assign"
                          class="button is-link button save"
                          @click="doInvite"
                        >
                          <span>Submit</span>
                          <b-icon
                            v-if="inviting"
                            size="is-small"
                            pack="fas"
                            icon="circle-notch"
                            custom-class="fa-spin"
                          ></b-icon>
                        </button>
                      </div>
                      <div class="control">
                        <button
                          id="cancel-assign"
                          class="button is-link is-light"
                          @click="
                            isComponentModalActive = false;
                            invited = '';
                          "
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </div>
</template>
<script>
import Avatar from "vue-avatar";
import anime from "animejs/lib/anime.es";
import { globalFunction } from "../services/global.function";
import { eventBus } from "../main";
export default {
  data() {
    return {
      SocketMsgIdList: [],
      userProfileImg: "",
      accType: "",
      accPersonal: false,
      accOrg: false,
      msUserName: "",
      notifyArr: [],
      owner: "",
      api: null,
      inviting: false,
      isComponentModalActive: false,
      invited: "",
      searchValue: "",
      userPhoto: "",
    };
  },
  components: {
    Avatar,
  },
  props: {
    justHeader: {
      type: Boolean,
      default: true,
    },
    hasStyle: String,
  },
  mounted() {
    this.userPhoto = localStorage.getItem("userPhoto");
    this.$root.$on("user-photo", () => {
      console.log("updating user photo");
      this.userPhoto = localStorage.getItem("userPhoto");
    });
    // console.log("userphoto from local storage:", this.userPhoto);
    this.$root.$on("socket-data", (data) => {
      console.log("Console log : mounted at header-> socketData", data);
      let isMsgPresent = false;
      this.SocketMsgIdList.forEach((element) => {
        if (element === data.id) {
          isMsgPresent = true;
        }
      });

      if (isMsgPresent) {
        console.log("ignoring duplicate socket message");
        return;
      }
      this.SocketMsgIdList.push(data.id);
      if (data.action) {
        if (data.user_profile) {
          var msg = data.action + " by " + data.user_profile.full_name;
          this.notifyArr.unshift({
            msg: globalFunction.cfl(msg),
            document_modified: data.document_modified,
          });
        }
      }
    });

    this.$root.$on("clear-notification", () => {
      console.log("Clearing Notification...");
      this.notifyArr = [];
      this.SocketMsgIdList = [];
    });
    // this.$root.$off("socket-data")
    if (!this.$props.justHeader) {
      this.init();
    }

    var userDetails = this.$store.getters.getUserDetails;
    console.log("Console log : mounted -> userDetails", userDetails);

    if (userDetails && userDetails.profile) {
      this.msUserName = userDetails.profile.full_name;
    }
    if (userDetails && userDetails.org_account && userDetails.user_org) {
      this.accType =
        userDetails.user_org[0].toUpperCase() + userDetails.user_org.slice(1);
    } else if (userDetails && userDetails.org_account) {
      this.accType = "Organization Account";
    } else {
      this.accType = "Personal Account";
    }

    if (this.accType == "Personal Account") {
      this.accPersonal = true;
    } else {
      this.accOrg = true;
    }
    anime({
      targets: ".account-type-indicator",
      width: 200,
      easing: "easeInOutQuad",
    });
  },
  computed: {
    callchanges() {
      return this.$store.state.socketMsg;
    },
    authFlowObject() {
      return this.$store.getters.authFlowObject;
    },
  },
  watch: {
    searchValue: {
      handler(newValue, oldValue) {
        console.log("search value: ", newValue, oldValue);
        if (newValue == "") {
          this.$root.$emit("search_bar_empty");
        } else {
          this.$root.$emit("search_bar_data", newValue);
        }
      },
    },
  },
  methods: {
    init: async function () {},
    setAccClass: () => {},
    closeWindow: () => {
      // ipcRenderer.send("close_windows");
    },
    maximizeWindows: () => {
      // ipcRenderer.send("maximize_windows");
    },
    minimizeWindows: () => {
      // ipcRenderer.send("minimize_windows");
    },
    clearNotify() {
      this.notifyArr = [];
    },
    callCreatePopup() {
      // this.$root.$emit("create_popup", true);
      eventBus.$emit("create_popup", true);
    },
    callVerifyPopup() {
      eventBus.$emit("verify_popup", true);
    },
    logout: async function () {
      await this.authFlowObject.signOut();
      await this.$store.dispatch("USER_LOGOUT");
      localStorage.removeItem("userPhoto");
      this.$router.push("/");
    },
    doInvite: async function () {
      this.inviting = true;
      let invitees = this.invited.split(",");
      invitees.forEach(async (invited, index) => {
        await this.authFlowObject.postReq("/user/invite", null, {
          email: invited,
        });
        if (index >= invitees.length - 1) {
          this.inviting = false;
          this.isComponentModalActive = false;
          this.invited = "";
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-menu > .dropdown-content{
  background-color: hsl(0deg, 0%, 100%);
    border-radius: 4px;
    box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    border-radius: 12px !important;
}
</style>
